/* Countdown Timer script */
(function ($) {
  'use strict';

  function getTimeDifference(endtime) {
    var t = endtime - new Date();
    var seconds = Math.floor((t / 1000) % 60);
    var minutes = Math.floor((t / 1000 / 60) % 60);
    var days = Math.floor(t / (1000 * 60 * 60 * 24));
    var hours = Math.floor((t / (1000 * 60 * 60)) % 24);

    return {
      'total': t,
      'days': days,
      'hours': hours,
      'minutes': minutes,
      'seconds': seconds
    };
  }

  function initializeClock(obj, endtime) {
    var $clock = $(obj);

    if ($clock.length === 0) {
      return;
    }
    var $daysElem = $clock.find('.js-countdown-clock__days');
    var $hoursElem = $clock.find('.js-countdown-clock__hours');
    var $minutesElem = $clock.find('.js-countdown-clock__minutes');
    var $secondsElem = $clock.find('.js-countdown-clock__seconds');
    var t = getTimeDifference(endtime);

    function updateClock() {
      // On timer complete
      if (t.total < 1000) {
        clearInterval(timeinterval);
        onTimerComplete($clock);

        return;
      }

      // Updating time
      if (t.seconds === 0) {
        t.days = t.days === 0 ? 0 : t.hours === 0 ? t.days - 1 : t.days;
        t.hours = t.days === 0 && t.hours === 0 ? 0 : t.hours === 0 ? 23 : t.minutes === 0 ? t.hours - 1 : t.hours;
        t.minutes = t.hours === 0 && t.minutes === 0 ? 0 : t.minutes === 0 ? 59 : t.minutes - 1;
        t.seconds = 60;
      }
      t.seconds -= 1;
      t.total -= 1000;

      if ($daysElem.length > 0) {
        $daysElem.html(t.days);
      }
      if ($hoursElem.length > 0) {
        $hoursElem.html(t.hours <= 9 ? '0' + t.hours : t.hours);
      }
      if ($minutesElem.length > 0) {
        $minutesElem.html(t.minutes <= 9 ? '0' + t.minutes : t.minutes);
      }
      if ($secondsElem.length > 0) {
        $secondsElem.html(t.seconds <= 9 ? '0' + t.seconds : t.seconds);
      }
    }
    updateClock();
    var timeinterval = setInterval(updateClock, 1000);
  }

  var onTimerComplete = function ($clockObj) {
    var textComplete = '';
    var $textCompleteElement = $clockObj.find('.js-countdown-clock__text-complete');

    if ($textCompleteElement.length > 0) {
      textComplete = $textCompleteElement.html();
    }
    if (textComplete.length) {
      // Hide the clock and show the complete text
      $clockObj.find('.js-countdown-clock__coming-soon').hide();
      $clockObj.find('.js-countdown-clock__watch').hide();
      $clockObj.find('.js-countdown-clock__tag-line').hide();
      $textCompleteElement.show();
    }
  };

  Drupal.behaviors.CountdownClockV2 = {
    init: function (context) {
      $('.js-countdown-clock-v2', context).each(function (i, obj) {
        var $fullDateElement = $(obj).find('.js-countdown-clock__full-date-value');

        if ($fullDateElement.length > 0) {
          var fullDate = $fullDateElement.html().replace(/&nbsp;/g, '');
          var endtime = new Date(fullDate);

          initializeClock(obj, endtime);
        }
      });
    },
    attach: function (context) {
      this.init(context);
    }
  };
})(jQuery);
